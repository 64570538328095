.auth__form{
    background: var(--primary-color);
    padding: 40px;
    border-radius: 5px;

}
.auth__btn{
    background: #f9d342 !important;
    color: var(--primary-color);
    font-weight: 600;
}
.auth__form p{
    margin-top: 30px;
}
.auth__form p a{
    color: rgb(255, 255, 255, 0.735);
}
.auth__form input[type='file']{
    color: #fff;
    cursor: pointer;
}