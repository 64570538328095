.products__box,.users__box{
    padding: 20px;
    border-radius: 5px;
    background: #000;
    text-align: center;
    align-items: center;

}
.products__box{
    background: #f9d342;
    color: var(--primary-color);
}
.users__box{
    background: #000;
    color: #f9d342;
}
.products__box h5,.users__box h5{
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 20px;
}
.products__box span,.users__box span{
    font-weight: 600;
    font-size: 2rem;
}
