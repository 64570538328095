.admin__header{
    width: 100%;
    padding: 20px 0px;
    background: var(--primary-color);
}
.admin__nav-top{
    width: 100%;
}
.admin__nav-wrapper-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 3rem;
}
.admin__nav-top-right img{
    width: 40px;
    height: 40px;
    border-radius: 50px;
    cursor: pointer;
}
.admin__nav-top-right{
    display: flex;
    align-items: center;
    column-gap: 2rem;
}
.admin__nav-wrapper-top .search__box{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.admin__nav-wrapper-top .search__box input{
    color: #fff;
}
.admin__nav-top-right span i{
    color: whitesmoke;
    cursor: pointer;
}
.admin__nav-wrapper-top .logo h2{
    font-size: 1.2rem;
    color: whitesmoke;
}
.admin__menu{
    width: 100%;
    height: 70px;
    line-height: 70px;
    background: #292826;
    border-bottom: 1px solid #f9d342;
}
.admin__navigation{
    text-align: center;
}
.admin__menu-list{
    display: flex;
    align-items: center;
    column-gap: 2.7rem;
    justify-content: center;
}
.admin__menu-list .admin__menu-item a{
    color: #fff;
    font-weight: 500;
    padding: 10px 15px;
    border-radius: 5px;
}
.active__admin-menu{
    background: #000;
    color: #f9d342;
}
