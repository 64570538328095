.product__item{
    cursor: pointer;
    border: 1px solid #fff;
    border-radius: 5px;
}

.product__info h3 a{
    font-size: 1.2rem;
    color: #f9d342;
    font-weight: 600;
    margin-top: 15px;
}
.product__info a:hover{
    color: #fff;
}  
.product__info span{
    font-size: 0.9rem;
}
.product__card-bottom .price{
    color: #f9d342;
    font-size: 1.3rem;
    font-weight: 500;
}
.product__card-bottom span i{
    font-size: 1.2rem;
    padding: 5px;
    background: var(--primary-color);
    color: #f9d342;
    border-radius: 50px;
}
@media only screen and (max-width:992px){
    .product__info h3 a{
        font-size: 1.1rem;
    }
}
@media only screen and (max-width:768px){
    .product__info h3 a{
        font-size: 1rem;
    }
    .product__card-bottom span i{
        font-size: 1rem;
    }
}