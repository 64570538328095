.header{
    width: 100%;
    height: 70px;
    line-height: 70px;
    background: var(--primary-color);
}
.nav__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.logo{
    display: flex;
    align-items: center;
    column-gap: 8px;
}
.logo img{
    width: 3rem;
    height: 3rem;
}
.logo h1{
    font-size:1.2rem ;
    font-weight: 700;
    color: #f9d342;
}
.menu{
    display: flex;
    align-items: center;
    column-gap: 2.7rem;
    margin-bottom: 0;
}
.nav__item a{
    color: #f9d342;
    font-weight: 500;
    cursor: pointer;
}
.nav__icons{
    display: flex;
    align-items: center;
    column-gap: 1.2rem;
}
.nav__icons span i{
    font-size: 1.4rem;
    color: #f9d342;
    cursor: pointer;
}
.cart__icon, .fav__icon{
    position: relative;
}
.badge{
    position: absolute;
    top: 25%;
    right: -8%;
    width: 15px;
    height: 15px;
    content: '';
    color: rgba(255,255,255);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
    font-weight: 600;
    z-index: 10;
}
.nav__icons .profile img{
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
}
.nav__icons .profile{
    position: relative;
    z-index: 9;
}
.nav__icons .profile .profile__actions{
    position: absolute;
    top: 98%;
    left: 0;
    width: 125px;
    z-index: 10;
    padding: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #292826;
    color: #f9d342;
    line-height: 30px;
    display: none;
    cursor: pointer;
}
.nav__icons .profile .profile__actions.show__profileActions{
    display: block;
}
.mobile__menu{
    font-size: 1.3rem;
    color: var(--primary-color);
    display: none;
}
.nav__active{
    font-weight: 700 !important;
}
.sticky__header{
    width: 100%;
    height: 70px;
    line-height: 70px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    box-shadow: 3px 3px 8px -3px #ddd;
    background: var(--primary-color);
}
.btns{
    display: none;
}
@media only screen and (max-width:768px){
    .navigation{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--primary-color);
        z-index: 9999;
        display: none;
    }
    
    .navigation .btns{
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 600;
        border: none;
        background-color: #131210;
        border: 1px solid #f9d342;
        color: #f9d342;
        border-radius: 5px;
        width: 140px;
        display: block;
        cursor: pointer;
        transition: var(--transition);
    }
    .nav__icons .profile .profile__actions.show__profileActions{
        display: none;
    }
    .btns:hover{
        opacity: 0.05;
    }
    
    .nav__item a{
        color: #f9d342;
        border-bottom: 0.5px solid rgba(249,211,66,0.3);
    }
    .nav__item a:hover{
        padding-left: 12px;
    }
    .menu{
        position: absolute;
        top: 0;
        right: 0;
        width: 250px;
        height: 100%;
        background: #292826;
        z-index: 99999;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .active__menu{
        display: block;
    }
    .mobile__menu{
        display: block;
    }
    .logo img{
        width: 1rem;
        height: 1rem;
    }
    .logo h1{
        font-size: 1rem;
    }
    .fav__icon i, .cart__icon i{
        font-size: 1.2rem !important;
    }
    .nav__icons span img{
        width: 1.4rem;
        height: 1.4rem;
    }
    .header{
        width: 100%;
        height: 60px;
        line-height: 60px;
    }
}