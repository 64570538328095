.carousel-wrapper img{
    height: 8rem;
    border-radius: 50%;
    border: 1px solid black;
    width: 10rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
}

.carousel-wrapper{
    overflow: hidden;
}
.card{
    border-radius: 50%;
    height: 10rem;
    width: 12rem !important;
    background: #a08939;
    column-gap: 0.7rem;
}
.card-content{
    align-items: center;
    text-align: center;
}
.card-content h4{
    color: rgb(240, 255, 240);
    font-family: serif;
    text-shadow:2px 2px 2px black;
}
@media only screen and (max-width:780px){
.card{
    width: 10rem !important;
}
.carousel-wrapper img{
    width: 8rem;
}
}
@media only screen and (max-width:320px){
.card{
    width: 9rem !important;
}
.carousel-wrapper img{
    width: 7rem;
}
.card-content h4{
    font-size: 1.2rem;
}
}