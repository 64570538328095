.table tr td img{
    width: 80px !important;
    height: 80px !important;
    object-fit: contain;
}
td i{
    cursor: pointer;
}
th{
    color: #fff;
}
td{
    color: #f9d342;
}
h2,h6{
    color: #f9d342;
}