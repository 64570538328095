.hero__section{
    background: #292826;
}
.hero__content{
    animation: stroll-in 2s ease-in-out forwards 1s;
}
@keyframes stroll-in{
    from{
        transform: translateY(-200%);
        opacity: 0;
    }
    to{
        transform: translateY(0);
        opacity: 1;
    }
}
.hero__content h2{
    color: rgba(255,255,255,0.735);
    font-size: 2.5rem;
    font-weight: 600;
    margin: 20px 0px;
    animation: stroll-right 2s ease-in-out forwards 1.5s;
}
@keyframes stroll-right{
    from{
        transform: translateY(200%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        opacity: 1;
    }
}
.carousel-caption{
    padding-bottom: 20rem;
    left: -20rem;
    bottom: -10rem;
}
.carousel-caption h1{
    font-size: 6rem;
    text-decoration: solid;
    text-shadow: 2px 5px#000;
    opacity: 0;
    animation: scale-in 1s ease-in-out forwards 1.5s;
}
.carousel-caption span{
    font-size: 1.5rem;
    box-shadow: 1px 1px rgba(255, 255, 255, 0.716);
    background: rgb(213, 26, 26);
    background-size: contain;
    color: #fff;
    font-family: serif;
    opacity: 0;
    animation: stroll-in 1s ease-in-out forwards 1s;
}
@keyframes scale-in{
    from{
        transform: scale(0)rotate(90deg);
        opacity: 0;
    }
    to{
        transform: scale(1)rotate(0);
        opacity: 1;
    }
}

.hero__content p{
    color: #fff;
    line-height: 28px;
}
.section__title{
    color: #f9d342;
}
.hero__section p:nth-child(1){
    font-weight: 500;
}
.buy__btn{
    border: none;
    outline: none;
    padding: 8px 20px;
    border-radius: 5px;
    background: var(--primary-color);
    color: #f9d342;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 40px;
}
.buy__btn a:hover{
    color: #fff;
}
.hero__content{
    padding-top: 45px;
}
.timer__count{
    background: var(--primary-color);
    height: 300px;
}
.timer__count img{
    width: 70% !important; 
    height: 70% !important;
    object-fit: contain;
}
.store__btn{
    background: #f9d342;
    color: var(--primary-color);
    font-weight: 600;
}
.store__btn a:hover{
    color: var(--primary-color);

}
@media only screen and (max-width:992px){
    .hero__content h2{
        font-size: 2rem;
    }
    .section__title{
        font-size: 1.7rem;
    }
    .carousel-caption{
        padding-bottom: 20rem;
        left: -10rem;
        bottom: -17rem;
    }    

}
@media only screen and (max-width:768px){
    .hero__content h2{
        font-size: 1.6rem;
    }
    .hero__content p{
        font-size: 0.9rem;
    }
    .buy__btn a{
        font-size: 0.9rem;
    }
    .section__title{
        font-size: 1.4rem;
    }
    .counter__img{
        display: none;
    }
    .clock__top-content{
        text-align: center;
    }
    .clock__top-content h4{
        font-size: 0.9rem !important;
    }
    .clock__top-content h3{
        font-size: 1rem !important;
    }
    .count__down-col{
        text-align: center;
    }
    .carousel-caption{
        padding-bottom: 20rem;
        left: -12rem;
        bottom: -17rem;
    }    
}
@media only screen and (max-width:576px){
    .hero__content{
        padding-top: 0;
        margin-bottom: 40px;
    }
}

@media only screen and (min-width:1024px){
    .carousel-inner{
        height: 600px;
    }
    .carousel-inner img{
        width: 600px;
        height: 600px;
    }    
    }